import React from "react"
import { Flex } from "@sqymagma/elements"
import styled from "styled-components"
import PropTypes from "prop-types"

const CssAspectRatioBox = styled(Flex)`
  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: ${p =>
      p.type === "1to1"
        ? "100%"
        : p.type === "4to3"
        ? "75%"
        : p.type === "16to9"
        ? "56.25%"
        : p.type === "2to1"
        ? "50%"
        : p.type === "3to1"
        ? "33.3%"
        : p.type === "4to1"
        ? "25%"
        : "100%"};
  }
  &::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }
`

const AspectRatioBox = ({ type, children, ...props }) => (
  <CssAspectRatioBox type={type} {...props}>
    {children}
  </CssAspectRatioBox>
)

AspectRatioBox.defaultProps = {
  type: "1to1",
}

AspectRatioBox.propTypes = {
  optionalEnum: PropTypes.oneOf(["1to1", "4to3", "16to9", "2to1", "3to1", "4to1"]),
}

export default AspectRatioBox
