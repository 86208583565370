const m16 = {
  title: "Invertir con Living Alternatives es fácil y seguro",
  t2: "Te contamos las razones por las que nuestros clientes confían en nosotros",
  cards: [
    {
      title: "Rentabilidad",
      text:
        "Conocemos el sector y las tendencias del mercado inmobiliario, y eso nos permite ofrecer a nuestros clientes oportunidades de inversión rentables y seguras.",
    },
    {
      title: "Facilidad",
      text:
        "Simplificamos el proceso de inversión, encargándonos de la gestión completa del inmueble: desde la adquisición del local hasta el alquiler de la vivienda.",
    },
    {
      title: "Compromiso",
      text:
        "Trabajamos desde el compromiso y transparencia en cada una de nuestras relaciones con un propósito claro: cumplir con nuestros clientes.",
    },
    {
      title: "Garantía",
      text:
        "Garantizamos que las viviendas resultantes cumplen con las disposiciones legales y de habitabilidad establecidas por la Normativa Urbanística.",
    },
    {
      title: "Profesionalidad",
      text:
        "Tratamos de manera personalizada a nuestros clientes con el objetivo de conocer sus necesidades, expectativas y objetivos de inversión.",
    },
    {
      title: "Diversificación",
      text:
        "Posibilidad de invertir en varios distritos de la capital madrileña, diversificando con ello el riesgo y la rentabilidad de las diferentes ubicaciones.",
    },
  ],
}

export default m16
