import React from "react"
import { Flex, Box, Columns, Text } from "@sqymagma/elements"
import BlockInner from "../components/blockinner"

import data from "../data/m15"

const Card = ({ cardID, title, text }) => {
  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      width={["100%"]}
      mb={["spc4", "spc4", "spc4", "spc4", "spc6"]}
      mx="auto"
    >
      <Box mx={["0", "0", "spc2", "0", "spc2"]} width={[48, 48, 48, 56, 64]}>
        <Flex
          mb="spc"
          width={[48, 48, 48, 56, 64]}
          height={[48, 48, 48, 56, 64]}
          bg="#EEDEC9"
          borderRadius="999px"
          justifyContent="center"
          alignItems="center"
          textStyle="p"
          color="#BC9B85"
          fontWeight="500"
        >
          {cardID}
        </Flex>
      </Box>
      <Box
        pt={["0", "0", "8px", "10px", "10px"]}
        ml={["0", "0", "spc1", "spc2", "spc3"]}
        mr={["0", "0", "spc2", "spc3", "spc3"]}
      >
        <Text textStyle="h4">{title}</Text>
        <Box height="24px" />
        <Text textStyle="p">{text}</Text>
      </Box>
    </Flex>
  )
}

const PorqueInvertir = () => {
  const listCards = data.map((card, i) => (
    <Card cardID={i + 1} title={card.title} text={card.text} />
  ))

  return (
    <BlockInner py="spc4">
      <Columns cols={[1, 2]} textAlign="left" px={["scp_2", "spc4", "0"]} hs="spc">
        {listCards}
      </Columns>
    </BlockInner>
  )
}

export default PorqueInvertir
