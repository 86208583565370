import React from "react"
import styled from "styled-components"
import { Box, Flex, Text } from "@sqymagma/elements"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import { Link } from "gatsby"
import Gatsbyimg from "./Gatsbyimg"
import AspectRatioBox from "../components/AspectRatioBox"

const CardContainer = styled(Flex)`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 8px rgba(0, 0, 0, 0.08);
`

const Card = ({ datacasos, tagmanagerid, title }) => {
  return (
    <Link to={datacasos.url} id={tagmanagerid} title={title}>
      <CardContainer textStyle="p" bg="bg03" flexDirection="column">
        <AspectRatioBox
          type="16to9"
          position="relative"
          className="aspectratiobox"
          overflow="hidden"
        >
          <Gatsbyimg
            alt={datacasos.altimage}
            filename={datacasos.image}
            css="object-fit:cover; position:absolute !important; width:100%; height:100%; z-index:0;"
          />
          <Text
            position="absolute"
            top="0"
            right="0"
            m="spc"
            bg="brand03"
            py="spc_2"
            px="spc"
            textStyle="p1_t"
            fontWeight="500 !important"
            alignSelf="end"
            borderRadius="99px"
          >
            {datacasos.datos.planInversion}
          </Text>

          <Text
            truncate
            textStyle="cardtitle"
            color="text02"
            position="absolute"
            bottom="0"
            left="0"
            bg="brand01"
            fontWeight="500"
            alignSelf="start"
            py="spc_2"
            px="spc1"
            borderRadius="0px 8px 0px 0px"
            maxWidth="100%"
          >
            {datacasos.titulo}
          </Text>
        </AspectRatioBox>
        <Box bg="bg03" my="spc_1">
          <Text truncate px="spc1" textStyle="cardtext" color="text03">
            {datacasos.direccion}
          </Text>
          <Flex flexDirection="row" alignItems="center" height="28px" width="100%" px="spc1">
            <IcnSystem name="icnescuadra" height="20px" fill="brand03" margin="0 8px 0 0" />
            <Text textStyle="p1" mr="spc">
              {datacasos.datos.m2} m2
            </Text>
            <IcnSystem name="icndormitorio" height="20px" fill="brand03" margin="0 8px 0 0" />
            <Text textStyle="p1" mr="spc">
              {datacasos.datos.habitaciones}
            </Text>
            <IcnSystem name="icnaseo" height="20px" fill="brand03" margin="0 8px 0 0" />
            <Text textStyle="p1">{datacasos.datos.aseos}</Text>
          </Flex>
        </Box>
      </CardContainer>
    </Link>
  )
}

export default Card
