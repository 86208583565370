import React from "react"
import { Box } from "@sqymagma/elements"

const BlockInner = ({ children, ...props }) => (
  <Box
    width={["calc(100% - 32px)", "calc(100% - 144px)", "896px", "1120px", "1472px"]}
    margin="0 auto"
    {...props}
  >
    {children}
  </Box>
)

export default BlockInner
