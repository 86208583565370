import React from "react"
import { Text, Flex } from "@sqymagma/elements"
import Btn from "../components/button"
import BtnNav from "../components/buttonNav"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import styled from "styled-components"

const CssBoton = styled(Btn)`
  @media (max-width: 767px) {
    padding: 0;
  }
  @media (min-width: 768px) {
    padding: 8px 24px 8px 16px;
  }
`
const CssBotonLast = styled(Btn)`
  @media (max-width: 767px) {
    padding: 0;
    margin-right: 48px;
  }
  @media (min-width: 768px) {
    padding: 8px 24px 8px 16px;
  }
`

const Share = ({ close, show, text, url }) => {
  const laurl = "https://livingalternatives.es/" + url
  let fb = "https://www.facebook.com/sharer/sharer.php?u=" + laurl + ""
  let twitter = "https://twitter.com/intent/tweet?text=" + text + "&url=" + laurl
  let linkedin = "https://www.linkedin.com/sharing/share-offsite/?url=" + laurl
  return (
    <Flex
      bg="brand02"
      position="fixed"
      zIndex="9999999"
      width="100%"
      bottom={show ? "0px" : "-120px"}
      css="transition: all 0.5s ease;"
    >
      <Flex alignItems="center" m={["0 auto"]} height={["80px", "104px"]}>
        <Text textStyle="p" color="text03" margin={["0 8px 0 8px", "0 16px 0 0"]}>
          Compartir
        </Text>
        <Flex>
          <a href={twitter} target="_blank" rel="noopener noreferrer">
            <CssBoton size="regular" margin="0 16px 0 0">
              <IcnSystem
                name="twitter"
                height="16px"
                fill="text02"
                margin={["16px", "8px", null, null, "8px 16px 8px 8px"]}
              />
              <Text textStyle="p" color="inverse01" display={["none", "Block"]}>
                Tweet
              </Text>
            </CssBoton>
          </a>{" "}
          <a href={fb} target="_blank" rel="noopener noreferrer">
            <CssBoton size="regular" margin="0 16px 0 0">
              <IcnSystem
                name="fb"
                height="16px"
                fill="text02"
                margin={["16px", "8px", null, null, "8px 16px 8px 8px"]}
              />
              <Text textStyle="p" color="inverse01" display={["none", "Block"]}>
                Post
              </Text>
            </CssBoton>{" "}
          </a>{" "}
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <CssBotonLast size="regular">
              <IcnSystem
                name="linkedin"
                height="16px"
                fill="text02"
                margin={["16px", "8px", null, null, "8px 16px 8px 8px"]}
              />
              <Text textStyle="p" color="inverse01" display={["none", "Block"]}>
                Post
              </Text>
            </CssBotonLast>{" "}
          </a>
        </Flex>
      </Flex>
      <BtnNav
        onClick={() => close()}
        position="absolute"
        translate="translate(0, -50%)"
        right={["10px", "30px"]}
        top="50%"
      />
    </Flex>
  )
}

export default Share
