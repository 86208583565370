import React from "react"
import Layout from "../modules/layout"
import Invertir from "../modules/m16_invertir"
import PorqueInvertir from "../modules/m15_porqueInvertir"
import Casos from "../modules/m20_casos"
import InnerHero from "../modules/m14_InnerHero"
import BlockInner from "../components/blockinner"
import Card from "../components/cardCaso"
import datacasos from "../data/casos"

const InvertirPage = () => {
  return (
    <Layout
      title="¿Por qué invertir en locales comerciales?"
      desc="Descubre por qué invertir en locales comerciales de una manera fácil y rentable. Queremos ayudarte para que obtengas la mayor rentabilidad de tu inversión. "
      keywords="Invertir en locales comerciales,
      Invertir en locales comerciales para alquilar,
      Cómo invertir en locales comerciales,
      Por qué invertir en locales comerciales"
      url="invertir-en-locales-comerciales"
    >
      <InnerHero
        bc="Invertir en locales"
        title="¿Por qué invertir en locales comerciales?"
        url="invertir-en-locales-comerciales"
        tagidbotshare="TM16-Invertir-MóduloCompartir-BotonCompartir"
      />
      <PorqueInvertir />
      <BlockInner>
        <Casos>
          <Card
            datacasos={datacasos[0]}
            tagmanagerid="TM17-Invertir-MóduloCasosPracticos-JuanIziar"
            title="De un restaurante a tres viviendas"
          ></Card>
          <Card
            datacasos={datacasos[1]}
            tagmanagerid="TM18-Invertir-MóduloCasosPracticos-SanVenancio"
            title="De un local en bruto a dos viviendas"
          ></Card>
          <Card
            datacasos={datacasos[2]}
            tagmanagerid="TM19-Invertir-MóduloCasosPracticos-PuertoReal"
            title="De una clínica dental a dos viviendas"
          ></Card>
        </Casos>
      </BlockInner>
      <Invertir />
    </Layout>
  )
}

export default InvertirPage
