const m15 = [
  {
    title: "Amplia oferta de locales vacíos y cerrados",
    text:
      "La llegada al mercado de grandes marcas, cadenas y centros comerciales, junto al aumento del comercio electrónico, han propiciado que el índice de ocupación comercial sea cada vez más bajo.",
  },
  {
    title: "Madrid, una inversión segura y rentable",
    text:
      "La ciudad madrileña se ha convertido en una de las ubicaciones más seguras y rentables para el mercado inmobiliario. La demanda de locales para uso comercial es prácticamente inexistente, por lo que el precio de éstos ha disminuido considerablemente.",
  },
  {
    title: "Alta demanda en el alquiler de viviendas de calidad",
    text:
      "La demanda de alquileres de calidad o nueva construcción en la capital es mucho más alta que la oferta disponible, y esto se ha traducido en un aumento progresivo de los precios del alquiler.",
  },
  {
    title: "Menor precio de compra que una vivienda",
    text:
      "Invertir en locales comerciales es una solución mucho más rentable que la inversión en vivienda, ya que éstas tienen un precio mayor que un local situado en una calle secundaria.",
  },
  {
    title: "Bajo riesgo en el cambio de uso",
    text:
      "En los últimos 20 meses hemos gestionado el cambio de uso a vivienda de 35 locales convirtiendo los mismos, tras un proceso de segregación, en 105 viviendas.",
  },
  {
    title: "Alta rentabilidad",
    text:
      "El alquiler de las viviendas resultantes alcanza rentabilidades entre el 8-11% en un período de inversión a corto-medio plazo.",
  },
  {
    title: "Diversificación",
    text:
      "Posibilidad de invertir y crear un portfolio de viviendas en diferentes distritos de la capital, diversificando con ello el riesgo y la rentabilidad en las diferentes ubicaciones.",
  },
]

export default m15
