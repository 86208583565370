import React, { useState } from "react"
import styled from "styled-components"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import { useStaticQuery, graphql } from "gatsby"
import BgImg from "gatsby-background-image-es5"
import Sharemodal from "./m30_compartir"
import Linkexternal from "../components/linkexternal"
import Gatsbyimg from "../components/Gatsbyimg"
import { Box, Text } from "@sqymagma/elements"

const Share = styled(Linkexternal)`
  float: right;
`

const Hero = ({ tagidbotshare, bc, title, url, alt, filename }) => {
  const [open, setOpen] = useState(false)

  const imgs = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "portadilla.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Box height={["256px", "384px", "384px", "384px", "512px"]} position="relative">
        <Gatsbyimg
          alt="Imagen de fondo con mobiliario de diseño"
          filename="portadilla.jpg"
          css="object-fit:cover; position:absolute; width:100%; height:100%; z-index:0;"
        />
      </Box>
      <Box
        bg="text02"
        width={["calc(100% - 16px)", "calc(100% - 80px)", "992px", "1216px", "1664px"]}
        mt={["-32px", "-48px", "-48px", "-48px", "-80px"]}
        mx="auto"
        position="relative"
        zIndex={4}
      >
        <Box
          bg="text02"
          pt={["spc1", "spc1", "spc2", "spc3", "spc6"]}
          px={["spc1", "spc2", "spc3", "spc3", "spc6"]}
        >
          <Share
            onClick={b => {
              b.preventDefault()
              setOpen(true)
            }}
            href="#"
            id={tagidbotshare}
            title="Compartir"
          >
            <IcnSystem name="share" height="24px" fill="brand01" />
          </Share>
          <Box pb={["spc_2", "spc_2", "spc", "spc", "spc1"]}>
            <Text textStyle="p" color="brand01">
              Home >{"  "}
            </Text>
            <Text textStyle="p">{bc}</Text>
          </Box>
          <Box pb={["spc2", "spc1", "spc3", "spc3", "spc3"]}>
            <Text as="h1" textStyle="h1">
              {title}
            </Text>
          </Box>
          <Box bg="bg02" height="2px"></Box>
        </Box>
      </Box>
      <Sharemodal show={open} close={() => setOpen(false)} text={title} url={url} />
    </>
  )
}

export default Hero
