import React from "react"
import { Columns, Text, Box } from "@sqymagma/elements"
import styled from "styled-components"
import Tick from "../components/tick"

const Cols = styled(Columns)`
  overflow: visible;
`

const Casos = ({ title, children, ...props }) => {
  return (
    <Box>
      <Box py={["spc4", "spc4", "spc6", "spc6", "spc8"]} {...props}>
        <Text textStyle="h3" color="text01" width={["250px", "420px", "530px"]}>
          {title}
        </Text>
        <Tick align="left" />
        <Cols hs={[0, "spc1"]} vs={["spc1"]} cols={[1, 2, 3]}>
          {children}
        </Cols>
      </Box>
    </Box>
  )
}

Casos.defaultProps = {
  title: "Casos prácticos",
}

export default Casos
