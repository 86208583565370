const datacasos = [
  {
    nombre: "Juan de Iziar",
    titulo: "De un restaurante a tres viviendas.",
    image: "juan-de-iziar/g1.jpg",
    altimage: "De un restaurante a tres viviendas.",
    url: "casos/juan-de-iziar",
    direccion: "Juan de Iziar, 28017 Madrid",
    desc: [
      "Cambio de uso para transformar un restaurante que había cesado su actividad por jubilación de sus propietarios a tres viviendas totalmente reformadas y diseñadas. ",
      "Hemos (re) convertido 90 m2 con licencia de local comercial en dos estudios de 26 m2 útiles y una vivienda de 38 m2 con 1 dormitorio independiente.",
    ],
    vr360: "https://my.matterport.com/show/?m=o2FmoPgEpzb",
    estado: {
      prev:
        "Antiguo restaurante de 90 m2 donde el espacio se dividía en 70 m2 de barra y comedor y 20 m2 de cocina y dos cuartos de baño",
      ref:
        "Con la redistribución del espacio hemos segregado el espacio en tres viviendas. Dos de ellas son estudios de 26 m2 y la tercera es una vivienda de 38 m2 con 1 dormitorio independiente.",
    },
    resultado: [
      "Las viviendas están totalmente reformadas y decoradas con materiales de alta calidad para ofrecer un espacio cómodo y listo para vivir. Están dirigidos a un público joven que valora los espacios rediseñados cerca de sus lugares de trabajo.",
    ],
    comparativa:
      "Situación antes y después del local comercial antes de cambiarle el uso y segregarlo en tres viviendas.",
    datos: {
      eltitulo: "Inversión realizada",
      nviv: "para 3 viviendas",
      m2: 100,
      precio: 1200000,
      aseos: 3,
      habitaciones: 3,
      ac: false,
      planInversion: "334.500€",
      precioCompra: "150.000€",
      gastosCompra: "21.000€",
      gastoReforma: "122.400€",
      gastosOtros: "41.100€",
      inversionTotal: "334.500€",
      ingresosBrutos: "32.400€",
      rentabilidadBruta: "9,69%",
      financiacion: "120.000€",
      rentabilidadPropia: "15,10%",
      total: "334.500€",
      totalud: "111.500€",
      infotext:
        "*El cálculo del rendimiento neto apalancado permite todos los costes, incluyendo el servicio de la hipoteca, los gastos de servicio, el alquiler del terreno, el seguro, la gestión de la propiedad y el cumplimiento y los informes de impuestos.",
    },
    seo: {
      keys: "fotos de locales convertidos en vivienda,cuanto cuesta convertir un local en vivienda",
      title: "¿Cuánto cuesta convertir un local en vivienda?|Living Alternatives",
      desc:
        "Conoce el antes y el después de un cambio de uso: fotos de locales convertidos en viviendas, precio de compra, aportación/retorno de capital, rendimiento neto.",
    },
  },
  {
    nombre: "San Venancio",
    titulo: "De un local en bruto a dos viviendas.",
    image: "san-venancio/g1.jpg",
    altimage: "De un local en bruto a dos viviendas.",
    url: "casos/san-venancio",
    direccion: "San Venancio, 28022 Madrid",
    desc: [
      "Cambio de uso para transformar un local en bruto a dos viviendas totalmente reformadas y diseñadas a 1 km del Wanda Metropolitano.",
      "Hemos (re) convertido 70 m2 con licencia de local comercial en dos estudios de 35 m2 con mucha luz en una Comunidad con piscina y jardines.",
    ],
    vr360: "https://my.matterport.com/show/?m=DSQxwwwkMtb",
    estado: {
      prev:
        "Era un local en bruto (de obra) que utilizaba la Comunidad de Propietarios cómo almacén para guardar las herramientas de mantenimiento del edificio y jardín.",
      ref:
        "Con la redistribución del espacio hemos segregado el espacio en dos apartamentos de 35 m2 con piscina  y zonas comunes.",
    },
    resultado: [
      "Una vez reformadas las viviendas, decoramos y amueblamos los espacios para ofrecer a los inquilinos la posibilidad de entrar a vivir en pocas horas y despreocuparse de la decoración y aprovisionamiento de la vivienda. A su vez el que las viviendas estén preparadas nos conlleva a conseguir mejores rentas de los alquileres y obtener con ello una mayor rentabilidad para nuestros inversores",
    ],
    comparativa:
      "Situación antes y después del local comercial antes de cambiarle el uso y segregarlo en dos viviendas.",
    datos: {
      eltitulo: "Inversión realizada",
      nviv: "para 2 viviendas",
      m2: 70,
      aseos: 2,
      habitaciones: 2,
      ac: true,
      planInversion: "220.000€",
      precioCompra: "97.000€",
      gastosCompra: "11.500€",
      gastoReforma: "79.000€",
      gastosOtros: "32.500€",
      inversionTotal: "220.000€",
      ingresosBrutos: "21.000€",
      rentabilidadBruta: "9,55%",
      financiacion: "90.000€",
      rentabilidadPropia: "16,15%",
      total: "220.000€",
      totalud: "110.000€",
      infotext:
        "*El cálculo del rendimiento neto apalancado permite todos los costes, incluyendo el servicio de la hipoteca, los gastos de servicio, el alquiler del terreno, el seguro, la gestión de la propiedad y el cumplimiento y los informes de impuestos.",
    },
    seo: {
      keys: "fotos de locales convertidos en vivienda,cuanto cuesta convertir un local en vivienda",
      title: "¿Cuánto cuesta convertir un local en vivienda?|Living Alternatives",
      desc:
        "Conoce el antes y el después de un cambio de uso: fotos de locales convertidos en viviendas, precio de compra, aportación/retorno de capital, rendimiento neto.",
    },
  },
  {
    nombre: "Puerto Real",
    titulo: "De una clínica dental a dos viviendas.",
    image: "puerto-real/g1.jpg",
    altimage: "De una clínica dental a dos viviendas.",
    direccion: "Puerto Real, 28043 Madrid",
    url: "casos/puerto-real",
    desc: [
      "Antigua clínica dental a 600 m del Metro de Arturo Soria, que desgraciadamente estaba okupada (y destrozada). Tras su adquisición la rediseñamos en dos preciosos luminosos apartamentos reformados y amueblados.",
      "Hemos (re) distribuido 60 m2 útiles con licencia de local comercial en dos estudios de 30 m2 útiles.",
    ],
    vr360: "https://my.matterport.com/show/?m=Scxm9pBL5vv&brand=0",
    estado: {
      prev:
        "Antigua clínica dental a 600 m del Metro de Arturo Soria que se encontraba destrozada tras un año de okupación ilegal. Eran 2 salas de consulta con una recepción, un despacho, una pequeña cocina y un baño.",
      ref:
        "Con la redistribución del espacio hemos segregado el espacio en dos estudios o apartamentos de 26 m2 con vistas a un parque.",
    },

    resultado: [
      "Las dos viviendas están ubicadas a 500 m2 del Metro Arturo Soria y la Jefatura de Tráfico de Arturo Soria.",
      "Las viviendas están totalmente reformadas y decoradas con materiales de alta calidad para ofrecer un espacio cómodo y preparado para empezar a vivir. Los inquilinos potenciales valoran su ubicación cerca del Metro y de bastantes centros empresariales.",
    ],
    comparativa:
      "Situación antes y después del local comercial antes de cambiarle el uso y segregarlo en tres viviendas.",
    datos: {
      eltitulo: "Inversión realizada",
      nviv: "para 2 viviendas",
      m2: 60,
      precio: 1200000,
      aseos: 2,
      habitaciones: 2,
      ac: true,
      planInversion: "251.300€",
      precioCompra: "125.000€",
      gastosCompra: "13.200€",
      gastoReforma: "84.000€",
      gastosOtros: "29.100€",
      inversionTotal: "251.300€",
      ingresosBrutos: "22.200€",
      rentabilidadBruta: "8,83%",
      financiacion: "75.000€",
      rentabilidadPropia: "12,59%",
      total: "251.300€",
      infotext:
        "*El cálculo del rendimiento neto apalancado permite todos los costes, incluyendo el servicio de la hipoteca, los gastos de servicio, el alquiler del terreno, el seguro, la gestión de la propiedad y el cumplimiento y los informes de impuestos.",
    },
    seo: {
      keys: "fotos de locales convertidos en vivienda,cuanto cuesta convertir un local en vivienda",
      title: "¿Cuánto cuesta convertir un local en vivienda?|Living Alternatives",
      desc:
        "Conoce el antes y el después de un cambio de uso: fotos de locales convertidos en viviendas, precio de compra, aportación/retorno de capital, rendimiento neto.",
    },
  },
  {
    nombre: "Zuera",
    titulo: "De una pescadería a un estudio.",
    image: "zuera/g1.jpg",
    altimage: "De una pescadería a un estudio.",
    direccion: "Calle Zuera, 28037 Madrid",
    url: "casos/zuera",
    desc: [
      "Cambio de uso de una pescadería a un estudio reformado y decorado a 500 m del Metro Simancas y García Noblejas.",
      "Hemos (re) convertido 32 m2 útiles con licencia de local comercial (pescadería) en un estudio de 32 m2 con mucho encantado",
    ],
    vr360: "https://my.matterport.com/show/?m=ibJtztmv4Xw",
    estado: {
      prev:
        "Era una pescadería que estaba cerrada.  Hubo que desescombrar y quitar todas las cámaras frigoríficas del local.",
      ref:
        "Con la reforma y redistribución del espacio hemos creado un apartamento o estudio de 32 m2 con mucho encanto ya que hemos mantenido parte de los ladrillos de una de las paredes dejándolos en su estado original.",
    },
    resultado: [
      "Precioso apartamento muy original por su ladrillo visto y con muchísima luz. Muy bien ubicado a 500 m2 de dos paradas de metro.",
      "Ideal para un publico single o pareja joven que busquen ubicaciones bien comunicadas con sus centros de trabajo pero en un barrio familiar y con amplias zonas públicas ajardinadas.",
    ],
    comparativa:
      "Situación antes y después del local comercial antes de cambiarle el uso y segregarlo en dos viviendas.",
    datos: {
      eltitulo: "Inversión realizada",
      m2: 32,
      aseos: 1,
      habitaciones: 1,
      ac: true,
      planInversion: "109.500€",
      precioCompra: "40.000€",
      gastosCompra: "10.000€",
      gastoReforma: "41.000€",
      gastosOtros: "18.500€",
      inversionTotal: "109.500€",
      ingresosBrutos: "10.800€",
      rentabilidadBruta: "9,86%",
      financiacion: "35.000€",
      rentabilidadPropia: "14,50%",
      total: "109.500€",
      infotext:
        "*El cálculo del rendimiento neto apalancado permite todos los costes, incluyendo el servicio de la hipoteca, los gastos de servicio, el alquiler del terreno, el seguro, la gestión de la propiedad y el cumplimiento y los informes de impuestos.",
    },
    seo: {
      keys: "fotos de locales convertidos en vivienda,cuanto cuesta convertir un local en vivienda",
      title: "¿Cuánto cuesta convertir un local en vivienda?|Living Alternatives",
      desc:
        "Conoce el antes y el después de un cambio de uso: fotos de locales convertidos en viviendas, precio de compra, aportación/retorno de capital, rendimiento neto.",
    },
  },
]

export default datacasos
