import React from "react"
import { Box, Columns, MaxWidth, Text } from "@sqymagma/elements"
import Block from "../components/block"

import data from "../data/m16"

const Card = ({ cardID, title, text }) => {
  return (
    <Box textAlign="center" p={["spc_2", "spc1", "spc3", "spc3", "spc4"]}>
      <Box pb="spc_2">
        <Text textStyle="h5">0{cardID}</Text>
      </Box>
      <Box pb={["spc1", "spc2"]}>
        <Text textStyle="subtitle">{title}</Text>
      </Box>
      <Box textAlign={["center", "left", "left", "left", "center"]}>
        <Text textStyle="p">{text}</Text>
      </Box>
    </Box>
  )
}

const Invertir = () => {
  const listCards = data.cards.map((card, i) => (
    <Card cardID={i + 1} title={card.title} text={card.text} />
  ))
  return (
    <Box bg="brand05">
      <MaxWidth py="spc6" textAlign="center">
        <Block>
          <Box mx={[0, 64, 120, 192, 256]} mb={["spc3", "spc3", "spc6"]}>
            <Text textStyle="h3">{data.title}</Text>
            <Box pt="spc2" />
            <Text textStyle="p">{data.t2}</Text>
          </Box>
          <Columns cols={[1, 3]} textAlign="left" mt="-24px">
            {listCards}
          </Columns>
        </Block>
      </MaxWidth>
    </Box>
  )
}

export default Invertir
